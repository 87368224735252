import * as _ModalContent2 from "@shopify/app-bridge-core/actions/Modal/ModalContent";
var _ModalContent = _ModalContent2;
try {
  if ("default" in _ModalContent2) _ModalContent = _ModalContent2.default;
} catch (e) {}
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.create = exports.ModalContent = exports.Action = void 0;
var ModalContent_1 = _ModalContent;
Object.defineProperty(exports, "ModalContent", {
  enumerable: true,
  get: function () {
    return ModalContent_1.ModalContent;
  }
});
var ModalContent_2 = _ModalContent;
Object.defineProperty(exports, "Action", {
  enumerable: true,
  get: function () {
    return ModalContent_2.Action;
  }
});
/**
 * @public
 */
function create(app, options) {
  return new ModalContent_1.ModalContent(app, options);
}
exports.create = create;
export default exports;