import * as _Toast2 from "@shopify/app-bridge-core/actions/Toast";
var _Toast = _Toast2;
try {
  if ("default" in _Toast2) _Toast = _Toast2.default;
} catch (e) {}
var exports = {};
var __extends = exports && exports.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.create = exports.Flash = exports.show = exports.clear = void 0;
var Toast_1 = _Toast;
Object.defineProperty(exports, "clear", {
  enumerable: true,
  get: function () {
    return Toast_1.clear;
  }
});
Object.defineProperty(exports, "show", {
  enumerable: true,
  get: function () {
    return Toast_1.show;
  }
});
var Flash = /** @class */function (_super) {
  __extends(Flash, _super);
  function Flash() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  return Flash;
}(Toast_1.Toast);
exports.Flash = Flash;
function create(app, options) {
  return new Flash(app, options);
}
exports.create = create;
export default exports;