import * as _Pos2 from "@shopify/app-bridge-core/actions/Pos";
var _Pos = _Pos2;
try {
  if ("default" in _Pos2) _Pos = _Pos2.default;
} catch (e) {}
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.create = exports.Pos = exports.close = exports.Action = void 0;
var Pos_1 = _Pos;
Object.defineProperty(exports, "Pos", {
  enumerable: true,
  get: function () {
    return Pos_1.Pos;
  }
});
var Pos_2 = _Pos;
Object.defineProperty(exports, "Action", {
  enumerable: true,
  get: function () {
    return Pos_2.Action;
  }
});
Object.defineProperty(exports, "close", {
  enumerable: true,
  get: function () {
    return Pos_2.close;
  }
});
function create(app) {
  return new Pos_1.Pos(app);
}
exports.create = create;
export default exports;