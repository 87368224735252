import * as _History2 from "@shopify/app-bridge-core/actions/Navigation/History";
var _History = _History2;
try {
  if ("default" in _History2) _History = _History2.default;
} catch (e) {}
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.create = exports.replace = exports.push = exports.Action = exports.History = void 0;
var History_1 = _History;
Object.defineProperty(exports, "History", {
  enumerable: true,
  get: function () {
    return History_1.History;
  }
});
var History_2 = _History;
Object.defineProperty(exports, "Action", {
  enumerable: true,
  get: function () {
    return History_2.Action;
  }
});
Object.defineProperty(exports, "push", {
  enumerable: true,
  get: function () {
    return History_2.push;
  }
});
Object.defineProperty(exports, "replace", {
  enumerable: true,
  get: function () {
    return History_2.replace;
  }
});
function create(app) {
  return new History_1.History(app);
}
exports.create = create;
export default exports;