import * as _Share2 from "@shopify/app-bridge-core/actions/Share";
var _Share = _Share2;
try {
  if ("default" in _Share2) _Share = _Share2.default;
} catch (e) {}
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.create = exports.Share = exports.close = exports.show = exports.Action = void 0;
var Share_1 = _Share;
Object.defineProperty(exports, "Share", {
  enumerable: true,
  get: function () {
    return Share_1.Share;
  }
});
var Share_2 = _Share;
Object.defineProperty(exports, "Action", {
  enumerable: true,
  get: function () {
    return Share_2.Action;
  }
});
Object.defineProperty(exports, "show", {
  enumerable: true,
  get: function () {
    return Share_2.show;
  }
});
Object.defineProperty(exports, "close", {
  enumerable: true,
  get: function () {
    return Share_2.close;
  }
});
/**
 * @public
 */
function create(app) {
  return new Share_1.Share(app);
}
exports.create = create;
export default exports;