import * as _Performance2 from "@shopify/app-bridge-core/actions/Performance";
var _Performance = _Performance2;
try {
  if ("default" in _Performance2) _Performance = _Performance2.default;
} catch (e) {}
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fullPageLoad = exports.skeletonPageLoad = exports.Action = void 0;
var Performance_1 = _Performance;
Object.defineProperty(exports, "Action", {
  enumerable: true,
  get: function () {
    return Performance_1.Action;
  }
});
Object.defineProperty(exports, "skeletonPageLoad", {
  enumerable: true,
  get: function () {
    return Performance_1.skeletonPageLoad;
  }
});
Object.defineProperty(exports, "fullPageLoad", {
  enumerable: true,
  get: function () {
    return Performance_1.fullPageLoad;
  }
});
export default exports;