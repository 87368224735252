import * as _FeedbackModal2 from "@shopify/app-bridge-core/actions/FeedbackModal";
var _FeedbackModal = _FeedbackModal2;
try {
  if ("default" in _FeedbackModal2) _FeedbackModal = _FeedbackModal2.default;
} catch (e) {}
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.create = exports.FeedbackModal = exports.close = exports.open = exports.Action = void 0;
var FeedbackModal_1 = _FeedbackModal;
Object.defineProperty(exports, "Action", {
  enumerable: true,
  get: function () {
    return FeedbackModal_1.Action;
  }
});
Object.defineProperty(exports, "open", {
  enumerable: true,
  get: function () {
    return FeedbackModal_1.open;
  }
});
Object.defineProperty(exports, "close", {
  enumerable: true,
  get: function () {
    return FeedbackModal_1.close;
  }
});
Object.defineProperty(exports, "FeedbackModal", {
  enumerable: true,
  get: function () {
    return FeedbackModal_1.FeedbackModal;
  }
});
Object.defineProperty(exports, "create", {
  enumerable: true,
  get: function () {
    return FeedbackModal_1.create;
  }
});
export default exports;