import * as _ActionSet2 from "@shopify/app-bridge-core/actions/ActionSet";
var _ActionSet = _ActionSet2;
try {
  if ("default" in _ActionSet2) _ActionSet = _ActionSet2.default;
} catch (e) {}
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.unsubscribeActions = exports.ActionSetWithChildren = exports.ActionSet = void 0;
var ActionSet_1 = _ActionSet;
Object.defineProperty(exports, "ActionSet", {
  enumerable: true,
  get: function () {
    return ActionSet_1.ActionSet;
  }
});
Object.defineProperty(exports, "ActionSetWithChildren", {
  enumerable: true,
  get: function () {
    return ActionSet_1.ActionSetWithChildren;
  }
});
Object.defineProperty(exports, "unsubscribeActions", {
  enumerable: true,
  get: function () {
    return ActionSet_1.unsubscribeActions;
  }
});
export default exports;