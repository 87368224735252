import * as _buttonHelper2 from "@shopify/app-bridge-core/actions/buttonHelper";
var _buttonHelper = _buttonHelper2;
try {
  if ("default" in _buttonHelper2) _buttonHelper = _buttonHelper2.default;
} catch (e) {}
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSingleButton = void 0;
var buttonHelper_1 = _buttonHelper;
Object.defineProperty(exports, "getSingleButton", {
  enumerable: true,
  get: function () {
    return buttonHelper_1.getSingleButton;
  }
});
export default exports;